<div class="card card-custom">
  <div class="card-header card-header-tabs-line">
    <div class="card-title">
      <h3 class="card-label text-bold">Downloads</h3>
    </div>
    <div class="card-toolbar">
      <ul class="nav nav-tabs nav-bold nav-tabs-line">
        <li class="nav-item">
          <a class="nav-link active" data-toggle="tab" href="#sumitted">
            <span class="nav-icon"><i class="fas fa-hourglass-start"></i></span>
            <span class="nav-text">Submitted</span>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" data-toggle="tab" href="#verified">
            <span class="nav-icon"><i class="flaticon2-check-mark"></i></span>
            <span class="nav-text">Verified</span>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" data-toggle="tab" href="#rejected">
            <span class="nav-icon"><i class="flaticon2-check-mark"></i></span>
            <span class="nav-text">Rejected</span>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" data-toggle="tab" href="#allowedtopay">
            <span class="nav-icon"><i class="fab fa-amazon-pay"></i></span>
            <span class="nav-text">Allowed to pay</span>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" data-toggle="tab" href="#closed">
            <span class="nav-icon"><i class="fab fa-amazon-pay"></i></span>
            <span class="nav-text">Closed</span>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" data-toggle="tab" href="#completed">
            <span class="nav-icon"><i class="far fa-thumbs-up"></i></span>
            <span class="nav-text">Completed</span>
          </a>
        </li>
        <li class="nav-item" *ngIf="user.role === 'superadmin'">
          <a class="nav-link" data-toggle="tab" href="#sync">
            <span class="nav-icon"><i class="far fa-thumbs-up"></i></span>
            <span class="nav-text">SyncPayment</span>
          </a>
        </li>
        <li class="nav-item"  *ngIf="user.role === 'superadmin'">
          <a class="nav-link" data-toggle="tab" href="#reset">
            <span class="nav-icon"><i class="far fa-thumbs-up"></i></span>
            <span class="nav-text">Resetpay</span>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" data-toggle="tab" href="#alloted">
            <span class="nav-icon"><i class="far fa-edit"></i></span>
            <span class="nav-text">Alloted</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
  <div class="card-body">
    <div class="tab-content">
      <div class="tab-pane fade show active" id="sumitted" role="tabpanel">
        <div class="form-group row">
          <div class="col-xl-12">
            <select class="form-control" [(ngModel)]="selectedCourse">
              <option value="">Select</option>
              <option value="{{item.get('cCode')}}" *ngFor="let item of courseMap">{{item.get('cCode')}}
                [{{item.get('cName')}}]</option>
            </select>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-xl-4">
            <input type="text" onfocus="(this.type='date')" [(ngModel)]="from" class="form-control" name="" id=""
              placeholder="From" />
          </div>
          <div class="col-xl-4">
            <input type="text" onfocus="(this.type='date')" [(ngModel)]="to" class="form-control" name="" id=""
              placeholder="To" />
          </div>
          <div class="col-xl-4">
            <button (click)="download('submitted')" class="btn btn-success btn-block">Go</button>
          </div>
        </div>
      </div>
      <div class="tab-pane fade" id="verified" role="tabpanel">
        <div class="form-group row">
          <div class="col-xl-12">
            <select class="form-control" [(ngModel)]="selectedCourse">
              <option value="">Select</option>
              <option value="{{item.get('cCode')}}" *ngFor="let item of courseMap">{{item.get('cCode')}}
                [{{item.get('cName')}}]</option>
            </select>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-xl-4">
            <input type="text" onfocus="(this.type='date')" [(ngModel)]="from" class="form-control" name="" id=""
              placeholder="From" />
          </div>
          <div class="col-xl-4">
            <input type="text" onfocus="(this.type='date')" [(ngModel)]="to" class="form-control" name="" id=""
              placeholder="To" />
          </div>
          <div class="col-xl-4">
            <button (click)="download('verified')" class="btn btn-success btn-block">Go</button>
          </div>
        </div>
      </div>
      <div class="tab-pane fade" id="allowedtopay" role="tabpanel">
        <div class="form-group row">
          <div class="col-xl-12">
            <select class="form-control" [(ngModel)]="selectedCourse">
              <option value="">Select</option>
              <option value="{{item.get('cCode')}}" *ngFor="let item of courseMap">{{item.get('cCode')}}
                [{{item.get('cName')}}]</option>
            </select>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-xl-4">
            <input type="text" onfocus="(this.type='date')" [(ngModel)]="from" class="form-control" name="" id=""
              placeholder="From" />
          </div>
          <div class="col-xl-4">
            <input type="text" onfocus="(this.type='date')" [(ngModel)]="to" class="form-control" name="" id=""
              placeholder="To" />
          </div>
          <div class="col-xl-4">
            <button (click)="download('allowedtopay')" class="btn btn-success btn-block">Go</button>
          </div>
        </div>
      </div>
      <div class="tab-pane fade" id="closed" role="tabpanel">
        <div class="form-group row">
          <div class="col-xl-12">
            <select class="form-control" [(ngModel)]="selectedCourse">
              <option value="">Select</option>
              <option value="{{item.get('cCode')}}" *ngFor="let item of courseMap">{{item.get('cCode')}}
                [{{item.get('cName')}}]</option>
            </select>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-xl-4">
            <input type="text" onfocus="(this.type='date')" [(ngModel)]="from" class="form-control" name="" id=""
              placeholder="From" />
          </div>
          <div class="col-xl-4">
            <input type="text" onfocus="(this.type='date')" [(ngModel)]="to" class="form-control" name="" id=""
              placeholder="To" />
          </div>
          <div class="col-xl-4">
            <button (click)="download('closed')" class="btn btn-success btn-block">Go</button>
          </div>
        </div>
      </div>
      <div class="tab-pane fade" id="rejected" role="tabpanel">
        <div class="form-group row">
          <div class="col-xl-12">
            <select class="form-control" [(ngModel)]="selectedCourse">
              <option value="">Select</option>
              <option value="{{item.get('cCode')}}" *ngFor="let item of courseMap">{{item.get('cCode')}}
                [{{item.get('cName')}}]</option>
            </select>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-xl-4">
            <input type="text" onfocus="(this.type='date')" [(ngModel)]="from" class="form-control" name="" id=""
              placeholder="From" />
          </div>
          <div class="col-xl-4">
            <input type="text" onfocus="(this.type='date')" [(ngModel)]="to" class="form-control" name="" id=""
              placeholder="To" />
          </div>
          <div class="col-xl-4">
            <button (click)="download('rejected')" class="btn btn-success btn-block">Go</button>
          </div>
        </div>
      </div>
      <div class="tab-pane fade" id="completed" role="tabpanel">
        <div class="form-group row">
          <div class="col-xl-12">
            <select class="form-control" [(ngModel)]="selectedCourse">
              <option value="">Select</option>
              <option value="{{item.get('cCode')}}" *ngFor="let item of courseMap">{{item.get('cCode')}}
                [{{item.get('cName')}}]</option>
            </select>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-xl-4">
            <input type="text" onfocus="(this.type='date')" [(ngModel)]="from" class="form-control" name="" id=""
              placeholder="From" />
          </div>
          <div class="col-xl-4">
            <input type="text" onfocus="(this.type='date')" [(ngModel)]="to" class="form-control" name="" id=""
              placeholder="To" />
          </div>
          <div class="col-xl-4">
            <button (click)="download('completed')" class="btn btn-success btn-block">Go</button>
          </div>
        </div>
      </div>
      <div class="tab-pane fade" id="sync" role="tabpanel">
        <div class="d-flex justify-content-center">
          <select (change)="sync($event.target.value, 'completed')" class="form-control">
            <option value="">Select</option>
            <option value="{{item.get('cCode')}}" *ngFor="let item of courseMap">{{item.get('cCode')}}
              [{{item.get('cName')}}]</option>
          </select>
        </div>
      </div>
      <div class="tab-pane fade" id="reset" role="tabpanel">
        <div class="d-flex justify-content-center">
          <select (change)="resetpay($event.target.value, 'completed')" class="form-control">
            <option value="">Select</option>
            <option value="{{item.get('cCode')}}" *ngFor="let item of courseMap">{{item.get('cCode')}}
              [{{item.get('cName')}}]</option>
          </select>
        </div>
      </div>
      <div class="tab-pane fade" id="alloted" role="tabpanel">
        <div class="form-group row">
          <div class="col-xl-12">
            <select class="form-control" [(ngModel)]="selectedCourse">
              <option value="">Select</option>
              <option value="{{item.get('cCode')}}" *ngFor="let item of courseMap">{{item.get('cCode')}}
                [{{item.get('cName')}}]</option>
            </select>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-xl-4">
            <input type="text" onfocus="(this.type='date')" [(ngModel)]="from" class="form-control" name="" id=""
              placeholder="From" />
          </div>
          <div class="col-xl-4">
            <input type="text" onfocus="(this.type='date')" [(ngModel)]="to" class="form-control" name="" id=""
              placeholder="To" />
          </div>
          <div class="col-xl-4">
            <button (click)="download('alloted')" class="btn btn-success btn-block">Go</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
