import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AlertComponent } from './_components';
import { HomeComponent } from './home';
import { AccountService } from './_services';
import { AccountModule } from './account/account.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SocialLoginModule, AuthServiceConfig, LoginOpt } from 'angularx-social-login';
import { GoogleLoginProvider, FacebookLoginProvider } from 'angularx-social-login';
import { WebcamModule } from 'ngx-webcam';
import { FormsModule } from '@angular/forms';
import { CommonModule, HashLocationStrategy, LocationStrategy } from '@angular/common';
import { RxReactiveFormsModule } from '@rxweb/reactive-form-validators';
import { SuperAdminComponent } from './superadmin/superadmin.component';
import { VerifierComponent } from './verifier/verifier.component';;
import { AdminComponent } from './admin/admin.component';
import { SupportComponent } from './helpdesk/support/support.component';
import { ReportsComponent } from './reports/reports.component';;
import { ExecutiveComponent } from './executive/executive.component';
import { FinanceComponent } from './finance/finance.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { DswComponent } from './dsw/dsw.component'
  ;
// import { LayoutComponent } from './finance/layout/layout.component'
//   ;
import { HelpdeskComponent } from './helpdesk/helpdesk.component'
  ;
import { DeanComponent } from './dean/dean.component';
import { DownloadComponent } from './download/download.component';;
import { RegistrationPaperComponent } from './registration-paper/registration-paper.component'
import { MatDialogModule } from '@angular/material/dialog';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FyupComponent } from './fyup/fyup.component';
import { RectifierComponent } from './rectifier/rectifier.component';
import { FyupRegisterComponent } from './FYUP-register/fyup-register.component';
import { ResultComponent } from './result/result.component';



const fbLoginOptions: LoginOpt = {
  scope: 'email',
  return_scopes: true,
  enable_profile_selector: true
}; // https://developers.facebook.com/docs/reference/javascript/FB.login/v2.11

const googleLoginOptions: LoginOpt = {
  scope: 'profile email'
}; // https://developers.google.com/api-client-library/javascript/reference/referencedocs#gapiauth2clientconfig

const config = new AuthServiceConfig([
  {
    id: GoogleLoginProvider.PROVIDER_ID,
    provider: new GoogleLoginProvider('624796833023-clhjgupm0pu6vgga7k5i5bsfp6qp6egh.apps.googleusercontent.com', googleLoginOptions)
  },
  {
    id: FacebookLoginProvider.PROVIDER_ID,
    provider: new FacebookLoginProvider('552842785427896', fbLoginOptions)
  }
]);

export function provideConfig() {
  return config;
}
@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
  
    HttpClientModule,
    AppRoutingModule,
    AccountModule,
    
    NgbModule,
    SocialLoginModule,
    WebcamModule,
    FormsModule,
    ReactiveFormsModule,
    RxReactiveFormsModule,
    NgxDatatableModule
  ],
  declarations: [
  
    
    AppComponent,
    AlertComponent,
    HomeComponent,
    SuperAdminComponent,
    FyupComponent,
    
    
    VerifierComponent,
    AdminComponent,
    ExecutiveComponent,
    HelpdeskComponent,
    RectifierComponent,
    DownloadComponent,
    FyupRegisterComponent,
RegistrationPaperComponent

  ],
  providers: [
    {
      provide: AuthServiceConfig,
      useFactory: provideConfig
    },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    AccountService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
